/** @jsx jsx */
import { jsx, Button, Flex, Box, Container, Styled, Grid } from 'theme-ui'
import React, {useState} from 'react'
import { graphql, Link } from 'gatsby'
import SEO from "../components/seo"
import BreedImages from "../components/BreedImages"
import BlockContent, { defaultSerializers } from '@sanity/block-content-to-react'
import Mapbox from "../components/Mapbox"

import Certified from "../images/certified.svg"

const Breeder = ({data}) => {

  const breeder = data.breeder
  const images = data.breedImages.edges

  const [location, setLocation] = useState({
    state: breeder.state,
    zipcode: breeder.zipcode,
    latitude: breeder.latitude,
    longitude: breeder.longitude,
    zip: breeder.zipcode,
  })


  return (
    <section>
      <SEO title={breeder.title} />
      <Box sx={{width: '100%'}}>
        {images.length > 0 ?
          <BreedImages breed={images} />
        :
          null
        }
      </Box>
      <Container sx={{px: 4, mt: 5, maxWidth: 'max'}}>
        <Flex sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <Flex sx={{alignItems: 'center', mb: 1}}>
              {breeder.certified && (
                <img src={Certified} width="30px" sx={{mb: '0px', mr: 1}} />
              )}
              <Styled.h1 sx={{mb: '0px'}}>{breeder.title}</Styled.h1>
            </Flex>
            <div sx={{display: 'inline-block'}}>
              {breeder.breeds.map((breed,i) => (
                <Styled.h6 key={i} sx={{backgroundColor: '#f5f5f5', borderRadius: '99px', px: 5, py: 3}}>{breed.title}</Styled.h6>
              ))}
            </div>
          </Box>

          <Button variant='rounded' sx={{display: ['none','none','block']}}>
            <Flex sx={{alignItems: 'center', justifyContent: 'center'}}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style={{fill: "#fff"}}> 
                <path d="M16.5,3C13.605,3,12,5.09,12,5.09S10.395,3,7.5,3C4.462,3,2,5.462,2,8.5c0,4.171,4.912,8.213,6.281,9.49 C9.858,19.46,12,21.35,12,21.35s2.142-1.89,3.719-3.36C17.088,16.713,22,12.671,22,8.5C22,5.462,19.538,3,16.5,3z M14.811,16.11 c-0.177,0.16-0.331,0.299-0.456,0.416c-0.751,0.7-1.639,1.503-2.355,2.145c-0.716-0.642-1.605-1.446-2.355-2.145 c-0.126-0.117-0.28-0.257-0.456-0.416C7.769,14.827,4,11.419,4,8.5C4,6.57,5.57,5,7.5,5c1.827,0,2.886,1.275,2.914,1.308L12,8 l1.586-1.692C13.596,6.295,14.673,5,16.5,5C18.43,5,20,6.57,20,8.5C20,11.419,16.231,14.827,14.811,16.11z"></path>
              </svg> <span sx={{ml: 2}}>Save</span>
            </Flex>
          </Button>
 
        </Flex>
        <Grid columns={[1,1,'1.5fr 1fr']} gap={5} sx={{mt: 5, mb:6}}>
          <Box>
            <BlockContent blocks={breeder._rawOverview} serializers={defaultSerializers} projectId="9pk1j8nz" dataset="production"/>
          </Box>
          <Box>
            <Box sx={{backgroundColor: "#f5f5f5", borderRadius: 'medium', boxShadow: 'medium', p: 5, mb: 5}}>
              <Mapbox location={location} setLocation={setLocation}/>
              <Button variant='rounded' sx={{mt: 5, width: '100%'}}>+ Message</Button>
            </Box>
            {breeder.registered && (
              <Box sx={{backgroundColor: "#f5f5f5", borderRadius: 'medium', boxShadow: 'medium', px: 5, py: 3}}>
                <Flex sx={{alignItems: 'center'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 32 32" style={{fill:"#000000"}}><path d="M 16 4 C 9.3844239 4 4 9.3844287 4 16 C 4 22.615571 9.3844239 28 16 28 C 22.615576 28 28 22.615571 28 16 C 28 9.3844287 22.615576 4 16 4 z M 16 6 C 21.534697 6 26 10.465307 26 16 C 26 21.534693 21.534697 26 16 26 C 10.465303 26 6 21.534693 6 16 C 6 10.465307 10.465303 6 16 6 z M 20.949219 12 L 14.699219 18.25 L 11.449219 15 L 10.050781 16.400391 L 14.699219 21.050781 L 22.349609 13.400391 L 20.949219 12 z"></path></svg>
                  <Styled.h6 sx={{my: '0'}}>AKC or CKC Registered</Styled.h6>
                </Flex>
              </Box>
            )}
            {breeder.geneticallyTested && (
              <Box sx={{backgroundColor: "#f5f5f5", borderRadius: 'medium', boxShadow: 'medium', px: 5, py: 3, mt: 3}}>
                <Flex sx={{alignItems: 'center'}}>
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 32 32" style={{fill:"#000000"}}><path d="M 16 4 C 9.3844239 4 4 9.3844287 4 16 C 4 22.615571 9.3844239 28 16 28 C 22.615576 28 28 22.615571 28 16 C 28 9.3844287 22.615576 4 16 4 z M 16 6 C 21.534697 6 26 10.465307 26 16 C 26 21.534693 21.534697 26 16 26 C 10.465303 26 6 21.534693 6 16 C 6 10.465307 10.465303 6 16 6 z M 20.949219 12 L 14.699219 18.25 L 11.449219 15 L 10.050781 16.400391 L 14.699219 21.050781 L 22.349609 13.400391 L 20.949219 12 z"></path></svg>
                  <Styled.h6 sx={{my: '0'}}>Genetic Testing</Styled.h6>
                </Flex>
              </Box>
            )}
          </Box>
        </Grid>
      </Container>
    </section>
  )
}

export default Breeder

export const query = graphql`
  query($slug: String!) {
    breeder: sanityBreeder(slug: {current: {eq: $slug}}) {
      id
      title
      certified
      geneticallyTested
      registered
      email
      latitude
      longitude
      state
      city
      zipcode
      _rawOverview
      slug {
        current
      }
      breeds {
        title
      }
    }

    breedImages: allSanityBreedImage(filter: {breeder: {slug: {current: {eq: $slug}}}}) {
      edges {
        node {
          image {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          altText
          source
        }
      }
    }


  }
`