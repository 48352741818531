import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const styles = {
  width: "100%",
  height: "300px",
};

const Mapbox = ({location, setLocation}) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);


  // useEffect(() => {
  //   fetch('http://api.ipstack.com/check?access_key=7fe13f44041f3cddc045e315223f6c81')
  //     .then(response => response.json())
  //     .then(data => setLocation({
  //       state: data.region_name,
  //       zip: data.zip,
  //       latitude: data.latitude,
  //       longitude: data.longitude
  //     }));
  // },[])

  useEffect(() => {
    mapboxgl.accessToken = "pk.eyJ1Ijoic2h5Y2F0IiwiYSI6ImNrYW9oaDBtcTFhdDQyeWxvM3V0dTdhajkifQ.m9750Lh2TEceCf_WUhK5Jg";
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [location.longitude,location.latitude],
        zoom: 10
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
        map.addControl(
          new mapboxgl.GeolocateControl({
          positionOptions: {
          enableHighAccuracy: true
          },
          trackUserLocation: true
          })
        );
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map,location]);

  return <div ref={el => (mapContainer.current = el)} style={styles} />;
};

export default Mapbox;